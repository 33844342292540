import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {NotificationsService, NotificationTypes} from '@shared/services/notifications.service';
import {Injectable} from '@angular/core';
import {REQUEST_CONTEXT_TOKEN_DOMAIN_OBJECT_NAME} from './request-metadata-token';
import {IResponseMessages} from '@domain-api/response/response-messages';
import {catchError, tap} from 'rxjs/operators';
import {getMessagesForComponent} from '@domain-api/response/generic-response-messages';
import {ISnackbarDetailedErrorMessages} from '@shared/ui/error-snackbar/error-snackbar.component';
import {getMessageFrom400ErrorResponse} from '@domain-api/response/error-response-formatter';

@Injectable({
    providedIn: 'root',
})
class ApiResponseInterceptor implements HttpInterceptor {

    private readonly MIN_HTTP_ERROR_CODE: number = 400;

    private readonly interceptedHttpMethodsOnSuccessRequests: Array<string> = [
        'PUT',
        'POST',
        'DELETE',
    ];

    private readonly interceptedHttpMethodsRequests: Array<string> =
        [...this.interceptedHttpMethodsOnSuccessRequests, 'GET'];

    constructor(private notificationsService: NotificationsService) {
    }

    public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (!request.context.has(REQUEST_CONTEXT_TOKEN_DOMAIN_OBJECT_NAME)) {
            return next.handle(request);
        }

        if (!this.interceptedHttpMethodsRequests.includes(request.method)) {
            return next.handle(request);
        }

        return next.handle(request).pipe(
            tap((event: HttpEvent<unknown>): void => {
                if (!(event instanceof HttpResponse)) {
                    return;
                }

                const errorRequest: boolean = this.MIN_HTTP_ERROR_CODE <= event.status;
                if (errorRequest) {
                    return this.interceptErrorResponse(event, request);
                }
                this.interceptSuccessResponse(request);
            }),
            catchError((error: HttpErrorResponse) => {
                this.interceptErrorResponse(error, request);

                return throwError(() => error);
            }),
        );
    }

    private interceptSuccessResponse(request: HttpRequest<unknown>): void {
        if (!this.interceptedHttpMethodsOnSuccessRequests.includes(request.method)) {
            return;
        }

        this.showGenericSnackbar(request, false);
    }

    private interceptErrorResponse(event: HttpErrorResponse | HttpResponse<unknown>, request: HttpRequest<unknown>): void {
        if (event.status === 400) {
            this.showError400SpecificSnackbar(event);
        } else {
            this.showGenericSnackbar(request, true);
        }
    }

    private showError400SpecificSnackbar(event: HttpErrorResponse | HttpResponse<unknown>) {
        const errorMessage: ISnackbarDetailedErrorMessages = event instanceof HttpErrorResponse
            ? getMessageFrom400ErrorResponse(event)
            : {message: event.statusText, details: []};

        this.notificationsService.showErrorDetailsSnackBar(errorMessage);
    }

    private showGenericSnackbar(request: HttpRequest<unknown>, isErrorResponse: boolean): void {
        const componentName: string = request.context.get(REQUEST_CONTEXT_TOKEN_DOMAIN_OBJECT_NAME);
        const messages: Map<string, IResponseMessages> = getMessagesForComponent(componentName);
        const message: IResponseMessages | undefined = messages.get(request.method);

        if (isErrorResponse && message?.error) {
            this.notificationsService.showSnackbar(message.error, NotificationTypes.Error);
        } else if (message?.success) {
            this.notificationsService.showSnackbar(message.success, NotificationTypes.Success);
        }
    }
}

export {ApiResponseInterceptor};
