import {ApplicationRef, Component} from '@angular/core';
import {SwUpdate, VersionEvent, VersionReadyEvent} from '@angular/service-worker';
import {MatDialog} from '@angular/material/dialog';
import {filter, first} from 'rxjs/operators';
import {concat, interval, Observable} from 'rxjs';
import {MatToolbarRow} from '@angular/material/toolbar';
import {MatIcon} from '@angular/material/icon';
import {MatButton} from '@angular/material/button';
import {CoreConfirmationDialogComponent, IDialogConfirmationData} from '@angular-clan/core/confirmation-dialog';

@Component({
    selector: 'atv-new-version',
    templateUrl: './new-version-toolbar.html',
    styleUrls: ['./new-version-toolbar.scss'],
    standalone: true,
    imports: [MatToolbarRow, MatButton, MatIcon],
})
export class AtvNewVersionToolbarComponent {
    public isNewVersionAvailable: boolean = false;

    constructor(
        private swUpdate: SwUpdate,
        private appRef: ApplicationRef,
        private dialog: MatDialog,
    ) {
        this.registerCheckForUpdateTimer();
        this.registerNewVersionHandler();
        this.registerUnrecoverableStateHandler();
    }

    public refreshPage(): void {
        window.location.reload();
    }

    private registerNewVersionHandler(): void {
        this.swUpdate.versionUpdates
            .pipe(
                filter<VersionEvent, VersionReadyEvent>(
                    (current: VersionEvent): current is VersionReadyEvent => {
                        return current.type === 'VERSION_READY';
                    },
                ),
            )
            .subscribe((): void => {
                this.isNewVersionAvailable = true;
            });
    }

    // https://angular.io/guide/service-worker-communications#handling-an-unrecoverable-state
    private registerUnrecoverableStateHandler(): void {
        const confirmationData: IDialogConfirmationData = {
            title: $localize`:@@NEW_VERSION_CONFIRM_TITLE:NEW_VERSION_CONFIRM_TITLE`,
            description: $localize`:@@NEW_VERSION_CONFIRM_DESCRIPTION:NEW_VERSION_CONFIRM_DESCRIPTION`,
            confirmButtonLabel: $localize`:@@NEW_VERSION_CONFIRM_BUTTON:NEW_VERSION_CONFIRM_BUTTON`,
            hasCancelButton: false,
        };

        this.swUpdate.unrecoverable.subscribe(
            () => {
                this.dialog.open<CoreConfirmationDialogComponent, IDialogConfirmationData>(
                    CoreConfirmationDialogComponent, {data: confirmationData, panelClass: 'dialog-container--small'},
                )
                    .afterClosed()
                    .subscribe(() => {
                        this.refreshPage();
                    });
            },
        );
    }

    private registerCheckForUpdateTimer(): void {
        const appIsStable: Observable<boolean> = this.appRef.isStable.pipe(first((isStable: boolean) => isStable));
        const refreshInterval: Observable<number> = interval(60 * 1000);
        const intervalOnceAppIsStable: Observable<unknown> = concat(appIsStable, refreshInterval);

        intervalOnceAppIsStable.subscribe(
            (): void => {
                if (this.swUpdate.isEnabled) {
                    void this.swUpdate.checkForUpdate();
                }
            },
        );
    }
}
